.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 1200px;
}

.header {
  display: flex;
  align-items: center;
  flex-direction: row;

  height: 150px;

  .logo {
    width: 50px;
    height: 50px;
    background-image: url(../assets/logo.png);
    background-size: cover;
  }

  .title {
    flex-grow: 1;
    margin-left: 20px;
    font-size: 30px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
  }

  .download {
    width: 250px;
    height: 72px;
    line-height: 72px;
    text-align: center;
    background: #F7D713;
    border-radius: 40px;

    font-size: 24px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #000000;
    letter-spacing: 1px;
  }
}

.body {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  box-sizing: border-box;
}

.wrap{
  height: 600px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .left {
    position: relative;
    padding-top: 40px;

    .title {
      font-size: 40px;
      font-family: MicrosoftYaHei;
      color: #F7D713;
      line-height: 90px;
      letter-spacing: 2px;
    }

    .content {
      font-size: 20px;
      font-family: MicrosoftYaHei;
      color: #FFFFFF;
      opacity: .5;
      line-height: 40px;
    }

    .footer {
      position: absolute;
      bottom: 0;

      .download {
        width: 350px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        background: #F7D713;
        border-radius: 40px;

        font-size: 30px;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        color: #000000;
        letter-spacing: 2px;
      }

      .description {
        margin-top: 20px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #FFFFFF;
        opacity: .7;
        line-height: 21px;
      }
    }
  }

  .demo {
    width: 600px;
    height: 405px;
    background-image: url(../assets/demo.png);
    background-size: cover;
  }
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}
